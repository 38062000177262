<script setup lang="ts">
import {onMounted, ref,watch} from "vue";
import {useStore} from "@/store";
import {storeToRefs} from "pinia";
const store = useStore()
const {homeConfig} = storeToRefs(store)
const {getBaseInfo} = store
let lastScrollTop = 0;
let headerIn = ref(0);


const handelScroll = () => {
    let topnum = 100;
    let botnum = 140;
    if (window.innerWidth < 1200) {
      topnum = 50;
      botnum = 60;
    }
    let currentScroll =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentScroll < lastScrollTop) {
      headerIn.value = 1;
      if (lastScrollTop < topnum) {
        headerIn.value = 3;
      }
    } else {
      if (lastScrollTop > botnum) {
        headerIn.value = 2;
      }
    }

    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
}
onMounted(() => {
    // getBaseInfo()
   

    window.addEventListener(
      "scroll",
      handelScroll,
      false
    );
});


import { useRouter} from 'vue-router'
const router = useRouter()
// 监听当前路由
watch(
  () => router.currentRoute.value,
  (newValue: any) => {
    // console.log('newValue',newValue)
    setTimeout(() => {
      lastScrollTop=0
      headerIn.value = 0;
      handelScroll();
    }, 300);
   
  },
  { immediate: true }
)


let showSlide = ref(false);
</script>
<template>
  <header>
    <div
      class="float"
      :class="{ in: headerIn == 1, out: headerIn == 2, black: headerIn == 3 }"
    >
      <div class="wp">
       
        <div class="header">
          <div class="l">
            <router-link to="/" class="logo">
              <img :src="homeConfig.logo" alt="" />
            </router-link>
          </div>
          <div class="r center">
            <div class="h-nav">
              <div class="center" v-for="(item,index) in homeConfig.navList" :key="index">
                <router-link :to="item.link">{{item.title}}</router-link>
              </div>
            </div>
            <div class="mode-btn">
              <img src="@svg/header-rightsvg.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </header>
  <!-- 移动端 -->
  <div class="header_m">
    <div
      class="float"
      :class="{ in: headerIn == 1, out: headerIn == 2, black: headerIn == 3 }"
    >
      <div class="wp">
        <div class="hd-box">
          <div class="logo">
            <router-link to="/">
              <img src="@svg/header-logo.svg" alt="" />
            </router-link>
          </div>
          <div class="menuBtn" @click="showSlide = true">
            <img src="@svg/menu1.svg" alt="" />
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
    <div class="pop-nav" v-if="showSlide">
      <div class="wp">
        <div class="hd-box">
          <div class="logo">
            <router-link to="/">
              <img src="@svg/MF_Logo.svg" alt="" />
            </router-link>
          </div>
          <div class="menuBtn" @click="showSlide = false">
            <img src="@svg/menu2.svg" alt="" />
          </div>
        </div>
        <ul class="hdnav" @click="showSlide = false">
          <li  v-for="(item,index) in homeConfig.navList" :key="index">
                <router-link class="v1" :to="item.link">{{item.title}}</router-link>
              </li>
            
        </ul>
        <div class="pop-bot">
          <div class="ll">
            <div class="lang act">Chinese</div>
            <div class="lang">English</div>
          </div>
          <div class="rr"><img src="@svg/header-rightsvg.svg" alt="" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
header {
  max-width: 1760px;
  width: 100%;
  height: 140rem;
}
.header_m {
  display: none;
}
.header {
  width: 100%;
  height: 140rem;
  color: #f5f5f7;
}
.float {
  position: fixed;
  width: 100%;
  z-index: 99;
  // background: #000000;
  transition: .4s;
  top: 0;
  left: 0;
  &.in {
    transform: translateY(0rem) !important;
    transition: transform 0.4s;
    background: #000000;
  }
  &.out {
    transform: translateY(-140rem);
    transition: transform 0.4s;
    background: #000000;
  }
  /* &.black {
    background: #000;
  } */
  a {
    display: flex;
    color: inherit;
  }
  /* .router-link-active{
    color: #000;
  } */
}
@keyframes headerIn {
  0% {
    transform: translateY(-140rem) !important;
  }
  100% {
    transform: translateY(0rem) !important;
  }
}
@keyframes headerOut {
  0% {
    transform: translateY(0rem);
  }
  100% {
    transform: translateY(-140rem);
  }
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .header .r{
  flex:1;
} */
 .h-nav{
  display: flex;
  align-items: center;
  font-size: 16rem;
  line-height: 24rem;
  color: #f5f5f7;
 }
 .h-nav .center{
  min-width: 148rem;
  font-style: normal;
 }
 .h-nav .center:last-child{
  min-width: 106rem;
 }
header.fixed {
  position: fixed;
  top: 0;
}

.logo {
  display: flex;
  > img {
    width: 145rem;
  }
}
.center {
  display: flex;
  align-items: center;
}
.between {
  justify-content: space-between;
}
.line {
  height: 3px;
  flex-shrink: 0;
  background: var(
    --1,
    linear-gradient(
      92deg,
      #d33328 6.53%,
      #fee33c 28.81%,
      #f3f6d4 44.6%,
      #5de9f6 61.73%,
      #2535a0 87.99%
    )
  );
}
@media screen and (max-width: 1440px) {
  header,
  .header {
    height: 120rem;
  }
}
@media screen and (max-width: 1199px) {
  .header_m {
    display: block;
    height: 110rem;
    background: #000;
    &.sty1{
      height: auto;
      background: transparent;
    }
  }
  .float {
    &.out {
      transform: translateY(-110rem);
    }
  }
  @keyframes headerIn {
    0% {
      transform: translateY(-110rem) !important;
    }
    100% {
      transform: translateY(0rem) !important;
    }
  }
  @keyframes headerOut {
    0% {
      transform: translateY(0rem);
    }
    100% {
      transform: translateY(-110rem);
    }
  }
  header {
    display: none;
  }
  .logo img {
    width: 97rem;
  }

  .hd-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 33rem 0;
  }
  .pop-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 999;
    background-color: #000;
    > .wp {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  .pop-bot {
    /* position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; */
    .ll {
      display: flex;
      align-items: center;
      .lang {
        cursor: pointer;
        font-style: italic;
        text-transform: capitalize;
        color: #86868b;
        font-size: 14rem;
        margin-right: 8rem;
        &.act {
          color: #fff;
        }
      }
    }
    // .wp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #2b2b2b;
    padding-top: 30rem;
    padding-bottom: 30rem;
    // }
  }

  .hdnav {
    margin-top: -60rem;
    li {
      padding: 4rem 0;
    }
    .v1 {
      display: inline-block;
      vertical-align: middle;
      font-size: 28rem;
      font-style: italic;
      text-decoration: underline;
      color: #fff;
    }
    .router-link-active {
      &.v1 {
        &::before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 12rem;
          height: 16rem;
          background: url("@svg/Arrow.svg") no-repeat center center;
          background-size: contain;
          margin-right: 10rem;
        }
      }
    }
  }
}
</style>
