import {post} from "@/utils/request.ts";
import {apiUrl} from "./config.js"

export default class apiYjIndex {
    static module : string  = 'yj_index'
    static async baseInfo() {
        return post(apiUrl + `/home/yj_index/baseInfo`)
    }

    static async index(){
        return post(apiUrl + `/home/yj_index/index`)
    }
}
