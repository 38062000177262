import apiCommonCategory from "@/api/common-category.ts";
import {post} from "@/utils/request.ts";
import {apiUrl} from "./config.js"

export default class apiYjAutoArea extends apiCommonCategory{
    static module : string  = 'yj_auto_area'

    static async index() {
        return post(apiUrl + `/home/yj_auto_area/index`)
    }
}
