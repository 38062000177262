import { to } from 'await-to-js'
// import toast from './toast'
// import { Loading, Message } from '@femessage/element-ui'
// import NProgress from 'nprogress'
import {message} from "ant-design-vue";

export const getApiData = async(apiResultPromise:Promise<any>, successCode = 0, showLoading = false, showErrMessage = true, showSuccessMessage = false) => {
  const re = await getApiRes(apiResultPromise, successCode, showLoading, showErrMessage, showSuccessMessage)
  return re.data
}

export const getApiRes = async(apiResultPromise:Promise<any>, successCode = 0, showLoading = false, showErrMessage = true, showSuccessMessage = false) => {
  // let loading = null
  // if (showLoading) loading = Loading.service()
  // if (showLoading) NProgress.start()
  const [err, re] = await to(apiResultPromise)
  // if (showLoading && loading) loading.close()
  // if (showLoading) NProgress.done()
  if (re && re.code == successCode) {
    if (showSuccessMessage) message.success(re.msg || '操作成功')
    return re
  } else {
    let errMsg = '加载错误'
    if (re && re.msg) errMsg = re.msg
    else if (err && err.msg) errMsg = err.msg
    else if (err) errMsg = JSON.stringify(err)
    if (showErrMessage) {
      // toast.error(errMsg)
      message.error(errMsg)
    }
    let code = -1
    if (re && re.code) code = re.code
    else if (err && err.code) code = err.code
    return { code, msg: errMsg, data: [], res: re || err }
  }
}

export const sleep = async(time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

export default getApiData
