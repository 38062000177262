import axios from 'axios'
// import { MessageBox, Message } from '@femessage/element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
import {message, Modal} from "ant-design-vue";

// create an axios instance
const service = axios.create({
  baseURL: '/', // process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['token'] = getToken()
    // }
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code < 0) {
      // Message({
      //   message: res.message || res.msg || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      message.error(res.message || res.msg || 'Error')

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === -400) {
        // to re-login
        // MessageBox.confirm('您已退出登录，您可以取消以停留在此页面，或重新登录', '确定退出', {
        //   confirmButtonText: '重新登录',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
        Modal.confirm({
          content: '您已退出登录，您可以取消以停留在此页面，或重新登录',
          onOk() {
            // store.dispatch('user/resetToken').then(() => {
            //   location.reload()
            // })
          }
        })
      }
      return Promise.reject(res || new Error(res.msg || res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    message.error(error.message, 5000)
    return Promise.reject(error)
  }
)

const post = (url:string, data:Object={}) => {
  console.log('post',url, data)
  return service({
    url,
    method: 'post',
    data
  })
}
const get = (url:string, params:Object={}) => {
  return service({
    url,
    method: 'get',
    params
  })
}

export default service
export { post, get }
