import {defineStore} from "pinia";
import getApiData from "@/api/apiUtil.ts";
import apiYjIndex from "@/api/yjIndex.ts";

export const useStore = defineStore('store', {
    state: () => {
        return {
            appList: [],
            industryList: [],
            homeConfig: {
                logo: '',
                commercialIdea: '',
                copyright: '',
                beian: '',
                navList: [],
                footerList: []
            }
        }
    },
    actions: {
        async getBaseInfo(){
            if(this.appList.length > 0 && this.industryList.length > 0) return // 已经获取过
            let data = await getApiData(apiYjIndex.baseInfo())
            if(data){
                this.appList = data.appList
                this.industryList = data.industryList
                this.homeConfig = data.homeConfig
            }
        }
    }
})
