<script setup lang="ts">
import $ from "jquery";
import {onMounted, reactive, watch} from "vue";
import {useStore} from "@/store";
import {storeToRefs} from "pinia";
import {message, Modal} from "ant-design-vue";
import {getApiRes} from "@/api/apiUtil.ts";
import apiYjMessage from "@/api/message.ts";
import {useRoute} from "vue-router";

const $route = useRoute()
const store = useStore()
const {appList, industryList, homeConfig} = storeToRefs(store)
const {getBaseInfo} = store
const gotop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

watch(()=>$route, async ()=>{
    window.scroll({ top: 0, behavior: 'smooth' });
},{deep:true})

onMounted(() => {
    getBaseInfo()
  if (window.innerWidth < 1200) {
    $(".footer-nav .list").on("click", ".title", function () {
      $(this).toggleClass("active").siblings(".subbox").stop().slideToggle();
    });
  }
});

const submitData = reactive({
    email: ''
})
const submit = async () => {
  if(!submitData.email){
    return message.error('请输入邮箱')
  }
  const re = await getApiRes(apiYjMessage.submit(submitData),0,1,0,0)
    if(re.code==0) message.success(re.msg||'提交成功') //Modal.success({title: re.msg||'提交成功'})
    else Modal.error({title: re.msg||'提交失败'})
}
</script>
<template>

  
  <div class="gotop" @click="gotop">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="20"
        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 40 40)"
        fill="currentColor"
      />
      <path
        d="M19.2929 13.2929C19.6834 12.9024 20.3166 12.9024 20.7071 13.2929L27.0711 19.6569C27.4616 20.0474 27.4616 20.6805 27.0711 21.0711C26.6805 21.4616 26.0474 21.4616 25.6569 21.0711L20 15.4142L14.3431 21.0711C13.9526 21.4616 13.3195 21.4616 12.9289 21.0711C12.5384 20.6805 12.5384 20.0474 12.9289 19.6569L19.2929 13.2929ZM19 27L19 14L21 14L21 27L19 27Z"
        fill="black"
      />
    </svg>
  </div>
  <div class="body-line"></div>
  <div class="footer-nav">
    
    <div class="left">
      <div class="box">
        <span>The Brief <br />Case </span>
        <img src="@svg/footer-logo.svg" alt="" />
      </div>
      <div class="email">
        <h4 v-html="homeConfig.commercialIdea"></h4>
        <a-input type="text" v-model:value="submitData.email" name="email" id="email" placeholder="Email" />
        <button @click="submit">咨询</button>
      </div>
    </div>
    <div class="list">
      <ul v-for="(cat,index) in Object.keys(homeConfig.footerList)" :key="index">
        <li class="title">{{homeConfig.footerList[cat].title}}</li>
        <ul class="subbox" >
          <li v-for="(item,index) in homeConfig.footerList[cat].list" :key="index">
            <router-link :to="item.link">{{ item.title }}</router-link>
          </li>
        </ul>
      </ul>
    </div>
<!--    <div class="list">-->
<!--      <ul>-->
<!--        <li class="title">行业方案</li>-->
<!--        <ul class="subbox">-->
<!--          <li v-for="item in industryList" :key="item.id">-->
<!--            <router-link :to="`/detail?id=${item.id}&module=yj_industry_list`">{{ item.title }}</router-link>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </ul>-->
<!--      <ul>-->
<!--        <li class="title">应用服务</li>-->
<!--        <ul class="subbox">-->
<!--          <li v-for="item in appList" :key="item.id">-->
<!--            <router-link :to="`/detail?id=${item.id}&module=yj_app_list`">{{ item.title }}</router-link>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </ul>-->
<!--      <ul>-->
<!--        <li class="title">公司</li>-->
<!--        <ul class="subbox">-->
<!--          <li><router-link to="/industrySolutions">解决方案</router-link></li>-->
<!--          <li><router-link to="/WorkList">作品案例</router-link></li>-->
<!--          <li><router-link to="/appService">应用服务</router-link></li>-->
<!--          <li><router-link to="/about">关于我们</router-link></li>-->
<!--        </ul>-->
<!--      </ul>-->
<!--    </div>-->
  </div>
  <div class="copyright">
    <div class="left">
      {{ homeConfig.copyright }} <a href="https://beian.miit.gov.cn/" target="_blank">{{ homeConfig.beian }}</a>
    </div>
    <div class="right">
      <a href="https://www.facebook.com" target="_blank" class="link">
        <img src="@svg/f-i1.svg" alt="" />
      </a>
      <a href="https://www.instagram.com/" target="_blank" class="link">
        <img src="@svg/f-i2.svg" alt="" />
      </a>
    </div>
  </div>
</template>
<style scoped lang="less">
.footer-nav {
  position: relative;
  padding: 68rem 0 112rem;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    .box {
      width: 140rem;
      height: 168rem;
      border: 2px solid #ffffff;
      padding: 15rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > img {
        width: 58rem;
      }
      >span{
        font-weight: bold;
      }
    }
    .email {
      margin-left: 17rem;
      flex:1;
      h4 {
        font-size: 16rem;
        font-weight: normal;
      }
      input {
        border: 0;
        background: none;
        border-bottom: 2px solid #666;
        width: 175rem;
        height: 40rem;
        margin-top: 32rem;
        color: #ffffff;
        padding: 0;
        border-radius: 0;
      }
      input::-webkit-input-placeholder {color: #757575;}
      input::-moz-placeholder {color: #757575;}
      input::-ms-input-placeholder {color: #757575;}
      button {
        width: 100px;
        height: 40px;
        background: #666;
        display: block;
        margin-top: 14rem;
        cursor: pointer;
      }
    }
  }
  .list {
    width: 57%;
    flex-wrap: wrap;
    display: flex;
    font-size: 16rem;
    ul {
      width: 33.3333%;
      .title {
        color: #ffffff;
        padding-bottom: 21rem;
        position: relative;
        opacity: 0.85;
        margin-top: 0;
        &:after {
          content: "";
          display: block;
          width: 14px;
          height: 2px;
          background: #d9d9d9;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      li {
        margin-top: 20rem;
      }
    }
    .subbox {
      width: auto;
      li {
        cursor: pointer;
      }
      a{
        color: #fff;
        text-decoration: none;
        transition: .4s;
        &:hover{
          opacity: 0.8;
        }
      }
    }
  }
}
.copyright {
  font-size: 12px;
  line-height: 60rem;
  height: 60rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #2b2b2b;
  a{
    color: #fff;
  }
  .right {
    display: flex;
    align-items: center;
    .link {
      display: block;
      cursor: pointer;
      margin-left: 30rem;
      &:hover {
        img {
          opacity: 0.8;
        }
      }
      img {
        height: 14rem;
        transition: 0.4s;
      }
    }
  }
}
.gotop {
 /*  position: absolute;
  right: 0;
  top: -130rem; */
  position: relative;
  z-index: 999;
  cursor: pointer;
  width: 40rem;
  color: #666;
  margin: 70rem 0 -50rem auto;
  svg{
    transition: .4s;
  }
  &:hover{
    svg{
      transform: scale(1.08);
    }
    color: #fff;
  }
}

@media screen and (max-width: 1199px) {
  .footer-nav {
    display: block;
    padding-bottom: 0;
    padding-top: 55rem;
    .list {
      width: auto;
      display: block;
      ul {
        width: auto;
        li {
          margin-top: 0;
        }
        .title {
          opacity: 1;
          padding: 16rem 0;
          border-top: 1px solid #2b2b2b;
          &.active {
            &::after {
              content: "-";
            }
          }
          &::after {
            content: "+";
            top: 50%;
            right: 0;
            bottom: auto;
            left: auto;
            width: 24rem;
            height: 24rem;
            text-align: center;
            line-height: 24rem;
            background-color: transparent;
            color: #fff;
            font-size: 24rem;
            margin-top: -12rem;
          }
        }
      }
      .subbox {
        display: none;
        padding-bottom: 20rem;
        li {
          padding: 8rem 0;
          font-size: 14rem;
        }
      }
    }
   
    .left {
      margin-bottom: 56rem;
      .box{
        width: 108rem;
        height: 128rem;
        padding: 15rem 0 10rem 15rem;
        >span{
          letter-spacing: 0;
          line-height: 1.2;
        }
      }
      .email {
        h4{
          margin-top: -4rem;
          line-height: 1.3;
        }
        input {
          border-color: #fff;
          margin-top: -4rem;
        }
        button {
          background-color: #fff;
          color: #000;
          font-size: 16rem;
          letter-spacing: 2px;
        }
      }
    }
  }

  .copyright {
    flex-direction: column-reverse;
    line-height: 1.3;
    height: auto;
    align-items: start;
    padding-bottom: 20rem;
    border-top: 2px solid #2B2B2B;
    .right {
      padding: 35rem 0;
      .link {
        margin-left: 0;
        margin-right: 20rem;
        img {
          height: 18rem;
        }
      }
    }
  }

  .gotop {
    display: none;
  }
  .body-line {
    margin-top: 0;
    height: 1px;
  }
}
</style>
