<script setup lang="ts">
import { ref, onMounted, reactive } from "vue";
import fetchData from "../fetch.ts";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import getApiData from "@/api/apiUtil.ts";
import apiYjAutoArea from "@/api/autoArea.ts";


const datas = ref({});
let swiperLs = ref([]);
let handelSw = null;
onMounted(async () => {
    datas.value = await getApiData(apiYjAutoArea.index())
    if(datas.value.list){
        setTimeout(()=>{
            for (let i = 0; i < datas.value.list.length; i++) {
                swiperLs.value.push({
                    sw: new Swiper("#swimg" + i, {
                        speed: 800,
                        effect: 'fade',
                    }),
                    index: i,
                });
            }

            handelSw = (index: number, num: number) => {
                swiperLs.value[index].sw.slideTo(num);
                swiperLs.value[index].index = num;
            };
        }, 100)
    }

  // fetchData("/public/autoArea/home.json", (data) => {
  //   datas.value = reactive(data);
  //   if (datas.value.projectData.list) {
  //     setTimeout(() => {
  //       for (let i = 0; i < datas.value.projectData.list.length; i++) {
  //         swiperLs.value.push({
  //           sw: new Swiper("#swimg" + i, {
  //             speed: 800,
  //             // loop: true,
  //             // effect: 'fade',
  //             /* autoplay: {
  //       delay: 3000,
  //       stopOnLastSlide: false,
  //       disableOnInteraction: false,
  //     }, */
  //           }),
  //           index: 0,
  //         });
  //       }
  //
  //       handelSw = (index: number, num: number) => {
  //         swiperLs.value[index].sw.slideTo(num);
  //         swiperLs.value[index].index = num;
  //       };
  //     }, 100);
  //   }
  // });
});

function getImageUrl(name: String) {
  return new URL(`../assets/${name}`, import.meta.url).href;
}


</script>
<template>
  <div class="wp">
    <!-- 汽车领域 home-->
    <div class="nav">
      <div class="left">
        <h1>{{ datas?.title }}</h1>
        <p>{{ datas?.description }}</p>
      </div>
      <div class="right">
        <ul class="list-1">
          <li v-for="list in datas?.list">
            <h6>{{ list.type }}</h6>
            <div class="box">
              <div v-for="item in list?.list" class="name">{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="g-listb1">
      <div
        v-for="(list, index) in datas?.list"
        class="group"
        :key="index"
      >
        <div class="swiper-imgs">
          <div class="swiper-container" :id="'swimg' + index">
            <div class="swiper-wrapper">
              <div v-for="item in list?.list" class="swiper-slide">
                <div class="con">
                  <div class="left">
                    <div class="top">
                      <h3>{{ item.title }}</h3>
                      <div class="info">{{ item.info }}</div>
                      <router-link :to="`/detail?id=${item.id}&module=auto_area_list`">了解更多</router-link>
                    </div>
                  </div>

                  <div class="right">
                    <video
                      v-if="item.videoUrl"
                      :src="item.videoUrl"
                      :poster="getImageUrl(item.imgUrl)"
                      class="video"
                      muted
                      loop
                      autoplay
                      webkit-playsinline="true"
                      playsinline="true"
                    ></video>
                    <img :src="item.img" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul class="bottom" v-if="swiperLs.length">
          <li
            v-for="(item, subIndex) in list?.list"
            @click="handelSw(index, subIndex)"
            :class="{ act: subIndex === swiperLs[index].index }"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="advantage">
      <div class="left">
        <h3>{{ datas?.advantageTitle }}</h3>
      </div>
      <div class="right">
        <ul class="g-txtls1">
          <li v-for="(item, index) in datas?.advantage">
            <div class="con">
              <div class="tit">{{ item.title }}</div>
              <div class="idx">0{{ index + 1 }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less">

.g-listb1 {
  .group {
    position: relative;
    padding: 75rem 0 0;
    border-top: 2px solid #2b2b2b;
    margin-bottom: 75rem;
    .swiper-slide.swiper-slide-active{
      opacity: 1 !important;
    }
    .swiper-slide{
      opacity: 0 !important;
    }
  }
  .con {
    display: flex;
    justify-content: space-between;
    min-height: 653rem;
    position: relative;
    overflow: hidden;
  }
  .left {
    width: 26%;
  }
  .right {
    width: 66%;
  }
  .top {
    h3 {
      font-weight: normal;
      font-size: 30rem;
      color: #fff;
      margin-bottom: 14rem;
    }
    font-size: 16rem;
    color: #86868b;
    line-height: 1.375;
    .info {
      margin-bottom: 20rem;
    }
    a {
      text-decoration: none;
      color: #fff;
      padding-right: 30rem;
      background: url(@/assets/svg/more-red-arrow.svg) no-repeat right;
      background-size: 15rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 275rem;
    z-index: 2;
    li {
      font-size: 20rem;
      line-height: 1.5;
      padding: 18rem 0;
      color: #86868b;
      position: relative;
      border-bottom: 2rem solid rgba(43, 43, 43, 1);
      cursor: pointer;
      transition: 0.3s;
      &:last-child {
        border-bottom: none;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -2rem;
        left: 0;
        width: 0;
        height: 2rem;
        background: url(../assets/svg/line.svg) no-repeat left;
        background-size: cover;
        transition: 0.4s;
      }
    }
    .act {
      color: #fff;
      &::after {
        width: 100%;
      }
    }
  }
}
.advantage {
  overflow: hidden;
  border-top: 2px solid #2b2b2b;
  padding-top: 74rem;
  // padding-bottom: 33rem;
  margin-bottom: -27rem;
  .left {
    width: 26%;
    float: left;
    h3 {
      font-weight: normal;
      font-size: 30rem;
      color: #fff;
    }
  }
  .right {
    float: right;
    width: 66%;
  }
}

@media screen and (max-width: 1199px) {
  .g-listb1{
    .group{
      padding: 55rem 0;
      margin-bottom: 0;
      &:nth-child(1){
        padding-top: 0;
        border-top: none;
      }
    }
    .con{
      flex-direction: column-reverse;
      min-height: auto;
      .left,.right{
        width: auto;
      }
      .left{
        margin-bottom: 80rem;
      }
      .right{
        margin-bottom: 24rem;
      }
    }
    .top{
      h3{
        font-size: 28rem;
        margin-bottom: 8rem;
      }
    }
    .bottom{
      width: auto;
      position: static;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
        border-width: 1px;
        width: 48%;
        font-size: 14rem;
        padding: 12rem 0;
        /* text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; */
        &::after{
          bottom: 0;
        }
      }
    }
  }

  .advantage {
    padding-top: 56rem;
    padding-bottom: 56rem;
    margin-bottom: 0;
    .left {
      float: none;width: auto;
      margin-bottom: 36rem;
      h3 {
        font-size: 28rem;
      }
    }
    .right {
      float: none;width: auto;
    }
  }
}
</style>
