<script setup lang="ts">
import { ref, onMounted, reactive } from "vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import getApiData from "@/api/apiUtil.ts";
import apiYjIndex from "@/api/yjIndex.ts";
import {useRouter} from "vue-router";

const $router = useRouter()
// banner轮播
const bannerSwiper = ref(null);

let prevBan = () => {
  bannerSwiper.value.slidePrev();
};
let nextBan = () => {
  bannerSwiper.value.slideNext();
};

const initBannerSwiper = () => {
  bannerSwiper.value = new Swiper("#bannerSwiper", {
    speed: 800,
    // loop: true,
    /* autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      }, */
  });
};
// 解决方案选中状态
const activeIndex = ref(0);

const tabActive = (index: number) => {
  activeIndex.value = index;
};
// 企业场景应用选中状态
const activeIdx = ref(0);

const tab1Active = (index: number) => {
  activeIdx.value = index;
  sceneSwiper.value.slideTo(index);
};
// banner轮播
const sceneSwiper = ref(null);

const initSceneSwiper = () => {
  sceneSwiper.value = new Swiper("#sceneSwiper", {
    speed: 800,
     effect: 'fade',
    // loop: true,
    /* autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      }, */
  });
};


const data = ref({
    appList:[],
    bannerList:[],
    caseList:[],
    caseNum:0,
    countList:[],
    countTitle:'',
    customerList:[],
    industryTitle:'',
    industryList:[],
    rewardList: [],
    serviceList:[],
    coreServiceList: [],
    coreServiceShowNum: 6,
    title:''
})
onMounted(async () => {
    data.value = await getApiData(apiYjIndex.index())

  setTimeout(() => {
    // 初始化banner轮播
  initBannerSwiper();

  // 初始化企业场景应用轮播
  initSceneSwiper();
  }, 100);
});


</script>
<template>
  <div class="wp">
    <!-- banner -->
    <div class="banner">
      <a-row class="top">
        <a-col :xl="8" :lg="24" class="title">{{ data?.title }}</a-col>
        <a-col :xl="16" :lg="24" class="right">
          <p>头条事件</p>
          <div class="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              @click="prevBan"
            >
              <circle
                cx="20"
                cy="20"
                r="20"
                transform="matrix(-1 0 0 1 40 0)"
                fill="#666"
              />
              <path
                d="M13.2929 20.7071C12.9024 20.3166 12.9024 19.6834 13.2929 19.2929L19.6569 12.9289C20.0474 12.5384 20.6805 12.5384 21.0711 12.9289C21.4616 13.3195 21.4616 13.9526 21.0711 14.3431L15.4142 20L21.0711 25.6569C21.4616 26.0474 21.4616 26.6805 21.0711 27.0711C20.6805 27.4616 20.0474 27.4616 19.6569 27.0711L13.2929 20.7071ZM27 21L14 21L14 19L27 19L27 21Z"
                fill="black"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              @click="nextBan"
            >
              <circle
                cx="20"
                cy="20"
                r="20"
                transform="matrix(1 0 0 -1 0 40)"
                fill="#666"
              />
              >
              <path
                d="M26.7071 19.2929C27.0976 19.6834 27.0976 20.3166 26.7071 20.7071L20.3431 27.0711C19.9526 27.4616 19.3195 27.4616 18.9289 27.0711C18.5384 26.6805 18.5384 26.0474 18.9289 25.6569L24.5858 20L18.9289 14.3431C18.5384 13.9526 18.5384 13.3195 18.9289 12.9289C19.3195 12.5384 19.9526 12.5384 20.3431 12.9289L26.7071 19.2929ZM13 19L26 19L26 21L13 21L13 19Z"
                fill="black"
              />
            </svg>
          </div>
        </a-col>
      </a-row>
      <div class="swiper-container" id="bannerSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in data?.bannerList||[]" :key="index">
            <div class="img">
              <img :src="item.img" alt="" />
              <video
                v-if="0"
                src=""
                poster="@img/home/banner.jpg"
                muted
                loop
                autoplay webkit-playsinline="true" playsinline="true"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="solution">
      <a-row class="solution-title">
        <a-col :xl="8" :lg="24">{{ data?.industryTitle }}</a-col>
      </a-row>
      <a-row class="tab">
        <a-col
          v-for="(item, index) in data?.industryList" :key="index"
          :xl="4"
           :lg="24"
          :class="{ active: activeIndex === index }"
          @click="tabActive(index)"
        >
          <span><img :src="item.icon" />{{ item.title }}</span>
        </a-col>
      </a-row>
      <a-row justify="space-between" v-if="data?.industryList[activeIndex]">
        <a-col :xl="8" :lg="24" class="list-title">
          <span class="tips-m"> {{ data?.industryList[activeIndex].title }} </span>
          <p>
            {{ data?.industryList[activeIndex].info }}
          </p>
          <span class="tips-pc"> {{ data?.industryList[activeIndex].title }} </span>
        </a-col>
        <a-col :xl="14" :lg="24">
          <a-row class="list" justify="space-between">
            <a-col v-for="(item, index) in data?.industryList[activeIndex].list" :key="index">
              <img :src="item.icon" alt="" />
              <div>
                <h5>{{ item.title }}</h5>
                <p>
                  {{ item.info }}
                </p>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="body-line"></div>
    <!-- 品牌 -->
    <div class="brand">
      <a-row :gutter="{ lg:0, xl: 30 }">
        <a-col v-for="(item, index) in data?.customerList" :key="index" :xs="8" :sm="8" :md="8" :lg="8" :xl="4">
          <span class="pic">
            <img :src="item.img" alt="" />
          </span>
        </a-col>
      </a-row>
    </div>
    <div class="body-line mt30"></div>
    <!-- 作品案例 -->
    <div class="case">
      <div class="topping" v-if="data?.caseList.length>0">
        <a-row :gutter="{ lg:0, xl: 30 }">
          <a-col :lg="24" :xl="10" class="left">
            <div class="top">
              <h2>作品案例</h2>
              <img class="logo" :src="data?.caseList[0].logo" alt="" />
            </div>
            <div class="bl">
              <span class="tips">{{ data?.caseList[0].brand }}</span>
              <h3>{{ data?.caseList[0].title}}</h3>
              <span class="desc">{{ data?.caseList[0].viceTitle }}</span>
            </div>
          </a-col>
          <a-col :lg="24" :xl="14" class="right">
            <div class="pic">
              <div class="img" :style="'background-image: url(' + data?.caseList[0].cover + ');'"></div>
              <!-- <img :src="data?.caseList[0].cover" alt="" /> -->
            </div>
          </a-col>
        </a-row>
        
      </div>
      <div class="ue-info" v-if="data?.caseList.length>0">
          <div class="pic">
             <!-- <div class="img" :style="'background-image: url(' + data?.caseList[0].cover + ');'"></div> -->
             <img :src="data?.caseList[0].cover" alt="" class="img">
            </div>
          <div class="top">
            <div class="l">
              <div class="date">2022-10</div>
               <div class="tips">{{ data?.caseList[0].brand }}</div>
            </div>
            <div class="r">
              <img class="logo" :src="data?.caseList[0].logo" alt="" />
            </div>
          </div>
          <div class="tit">{{ data?.caseList[0].title }}</div>
        </div>
      <ul class="list" v-if="data?.caseList.length>1">
        <li v-for="item in data?.caseList.slice(1)" :key="item.id">
          <!-- <div class="img">
            <img :src="item.cover" />
          </div> -->
          <div class="pic">
            <div class="img" :style="'background-image: url(' + item.cover + ');'"></div>
          </div>
          <img src="@img/home/case-logo-2.svg" class="logo" />
          <div class="text">
            <span class="tips">{{ item.brand }}</span>
            <h3>{{ item.title }}</h3>
            <span class="desc">{{ item.viceTitle }}</span>
          </div>
          <div class="border"></div>
        </li>
        
      </ul>
      <img src="@svg/home/more.svg" alt="" class="more" @click="$router.push('/WorkList')" />
    </div>
    <div class="body-line"></div>
    <!-- 场景应用 -->
    <div class="scene">
      <div class="swiper-container" id="sceneSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in data?.appList" :key="index">
            <a-row justify="space-between" class="con">
              <a-col :lg="24" :xl="8" class="left">
                <div class="info">
                  <span>{{ item.viceTitle }}</span>
                  <h3>{{ item.title }}</h3>
                  <p>
                    {{ item.info }}
                  </p>
                  <router-link class="g-btnl" to="">了解更多<img src="@img/Metaverse/iconl1.png" alt=""></router-link>
                </div>
              </a-col>
              <a-col :lg="24" :xl="14" class="img">
                <img :src="item.img" alt="" />
                <!-- 如果是视频 -->
                <div class="g-videol" v-if="0">
                  <video
                  ref="videoElement"
                    src="http://yuanjingcar.dev3.lishengli.xin//uploads/20240720/7b1c704ba8052c8780f61a2924c84f1b.mp4"
                    poster="@img/home/scene-img-1.jpg"
                    class="video"
                    muted
                    loop
                    :autoplay="isPlaying"
                    webkit-playsinline="true"
                    playsinline="true"
                  ></video>
                  <div class="play" @click="togglePlay">
                    <img src="@img/playl.png" alt="">
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="item">
          <ul>
            <li v-for="(item,index) in data?.appList.slice(0, 3)" :key="index" :class="{ active: activeIdx === index }" @click="tab1Active(index)">
              <a>{{ item.title }}</a>
            </li>
          </ul>
        </div>
        <div class="item">
          <ul>
            <li v-for="(item,index) in data?.appList.slice(3)" :key="index" :class="{ active: activeIdx === 3+index }" @click="tab1Active(3+index)">
              <a>{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="body-line"></div>
    <!-- 行业积累 -->
    <div class="accumulate">
      <a-row justify="space-between">
        <a-col :lg="24" :xl="8" class="title">
          {{ data?.countTitle }}
        </a-col>
        <a-col :lg="24" :xl="14" class="list">
          <ul>
            <li v-for="(item,index) in data?.countList" :key="index">
              <span>{{ item.count }}</span>
              <p>{{ item.title }}</p>
            </li>
          </ul>
        </a-col>
      </a-row>
    </div>
    <div class="body-line"></div>
    <!-- 荣誉-->
    <div class="honor">
      <a-row justify="space-between">
        <a-col :lg="24" :xl="8" class="title"> 与伙伴一起获得荣誉 </a-col>
        <a-col  :lg="24" :xl="14" class="list">
          <ul>
            <li v-for="(item,index) in data?.rewardList" :key="index">
              <img :src="item.img" alt="" />
            </li>
          </ul>
        </a-col>
      </a-row>
    </div>
    <div class="body-line"></div>
    
  </div>
  <div class="wp1">
    <!-- 核心服务应用 -->
    <div class="service-app" v-if="(data?.coreServiceShowNum||0) > 0">
      <h3>核心服务应用</h3>
      <a-row :gutter="{ md:0, lg:20, xl: 30 }" class="list">
        <a-col v-for="(item,index) in data?.coreServiceList" :key="index" :md="24" :lg="12" :xl="8" class="item">
          <router-link v-if="index < (data?.coreServiceShowNum||0)" :to="item.link" class="con">
            <div class="img">
              <img :src="item.img" alt=""  />
<!--              <img :src="item.img" alt="" @click="$router.push(`/detail?id=${item.id}&module=yj_app_list`) " />-->
            </div>
            <h5>{{ item.title }}</h5>
            <p>
              {{ item.info }}
            </p>
          </router-link>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<style scoped lang="less">
.body-line.mt30{
  margin-top: 30rem;
}
.banner {
  .top {
    height: 260rem;
    display: flex;
    align-items: center;

    .title {
      font-size: 57rem;
      line-height: 1.2em;
      // font-weight: bold;
      padding-top: 14rem;
      background: var(
        --1,
        linear-gradient(
          92deg,
          #d33328 6.53%,
          #fee33c 28.81%,
          #f3f6d4 44.6%,
          #5de9f6 61.73%,
          #2535a0 87.99%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-size: 20rem;
      flex-direction: column;
      p{
        font-weight: bold;
      }

      .button {
        margin-top: 42rem;
        font-weight: 700;

        svg {
          margin-left: 24rem;
          cursor: pointer;
          transition: transform 0.4s;
          circle {
            transition: fill 0.4s;
          }
          &:hover {
            transform: scale(1.1);
            circle {
              fill: #ffffff; /* 你想要的颜色 */
            }
          }
        }
      }
    }
  }

  .img {
    img {
      width: 100%;
    }
  }
}
// 解决方案
.solution {
  .solution-title {
    > div {
      font-size: 30rem;
      padding: 70rem 0;
    }
  }

  .tab {
    > div {
      opacity: 0.5;
      font-size: 20rem;

      span {
        display: flex;
        align-items: center;
        position: relative;
        width: max-content;
        padding-bottom: 7rem;
        text-overflow:ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        > img {
          margin-right: 12rem;
        }
      }

      &.active {
        opacity: 1;

        span {
          &:after {
            content: "";
            display: block;
            background: linear-gradient(
              90deg,
              #d33328 7.35%,
              #fee33c 32.74%,
              #f3f6d4 50.71%,
              #5de9f6 70.23%,
              #2535a0 100.14%
            );
            height: 3px;
            width: 100%;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }

  .list-title {
    p {
      font-size: 30rem;
      line-height: 1.2em;
      padding-bottom: 32rem;
      padding-top: 53rem;
      letter-spacing: 0;
      padding-right: 20rem;
    }
    .tips-m{
      display: none;
    }
    span {
      font-size: 18rem;
    }
  }
  .list {
    margin-top: 56rem;
    border-left: 1px solid #2b2b2b;
    padding-left: 30rem;
    display: flex;
    flex-wrap: wrap;
    letter-spacing: normal;
    > div {
      border-bottom: 1px solid #2b2b2b;
      display: flex;
      padding-bottom: 50rem;
      padding-top: 50rem;
      width: 48%;
      &:nth-child(1),
      &:nth-child(2) {
        padding-top: 0;
      }
      &:nth-last-child(1),
      &:nth-last-child(2) {
        padding-bottom: 0;
        border: 0;
      }
      > img {
        width: 64rem;
        margin-right: 32rem;
      }
      h5 {
        font-size: 20rem;
        letter-spacing: 0;
      }
      p {
        font-size: 16rem;
        color: #707070;
        margin-top: 8rem;
        text-align: justify;
        text-align-last: left;
      }
    }
  }
}
// 品牌
.brand {
  margin-top: 68rem;
  .pic{
    display: block;
    width: 100%;
    text-align: center;
    line-height: 128rem;
    height: 128rem;
    img {
      display: inline-block;
      vertical-align: middle;
      // object-fit: contain;
      max-width: 70%;
      max-height: 70%;
    }
  }
}
// 案例
.case {
  margin-top: 68rem;
  .topping {
    .left {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      h2 {
        font-size: 38rem;
        font-weight: 400;
      }
      .logo {
        height: 50rem;
        margin-top: 52rem;
      }
      .bl {
        font-size: 18rem;
        h3 {
          font-size: 38rem;
          width: 73%;
          margin: 32rem 0;
          text-decoration-line: underline;
          font-weight: 500;
        }
      }
    }
    .right {
      .pic{
        padding-top: 56.114398422%;
        position: relative;
        height: 0;
        overflow: hidden;
        .img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -ms-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
        }
      }
      // > img {
      //   width: 100%;
      // }
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 39rem;
    @property --bg-angle {
      inherits: false;
      initial-value: 0deg;
      syntax: "<angle>";
    }

    @keyframes spin {
      to {
        --bg-angle: 360deg;
      }
    }

    li {
      width: 31%;
      position: relative;
      margin-top: 30rem;
      transition: opacity 0.4s;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      animation: spin 2.5s infinite linear paused;
      background: linear-gradient(to bottom, transparent, transparent)
          padding-box,
        conic-gradient(
            from var(--bg-angle),
            #d33328 6.53%,
            #fee33c 28.81%,
            #f3f6d4 44.6%,
            #5de9f6 51.73%,
            #2535a0 70.99%,
            #d33328 99%
          )
          border-box;
      &:hover {
        animation-play-state: running;
      }
      .border {
        top: 0;
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all 0.4s;
      }
      // .img {
      //   background: #000000;
      //   transition: transform 0.5s;
      //   > img {
      //     width: 100%;
      //     display: block;
      //     transition: opacity 0.5s;
      //   }
      // }
      .pic{
        padding-top: 56.22710622%;
        position: relative;
        height: 0;
        overflow: hidden;
        background:#000000;
        // transition:transform 0.5s;
        transition: .5s;
        .img{
          width:100%;
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-repeat: no-repeat;
          background-position: center center;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -ms-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
          transition: .5s;
        }
      }
      .logo {
        position: absolute;
        top: 30rem;
        left: 30rem;
      }
      .text {
        position: absolute;
        left: 30rem;
        bottom: 30rem;
        h3 {
          font-size: 24rem;
          // font-weight: 600;
          font-weight: normal;
          margin: 5rem 0 9rem;
        }
      }
      &:hover {
        .pic{
          transform:scale(0.98,0.97);
          .img{
            opacity:0.6;
          }
        }
      }
    }
  }
  .more {
    width: 209rem;
    margin: 0 auto;
    margin-top: 60rem;
    display: block;
    cursor: pointer;
  }
}
// 场景
.scene {
  margin-top: 68rem;
  position: relative;
  .swiper-slide.swiper-slide-active{
    opacity: 1 !important;
  }
  .swiper-slide{
    opacity: 0 !important;
  }
  .left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    h3 {
      font-size: 26rem;
      margin: 18rem 0 16rem;
    }
    span,
    p {
      // font-size: 16rem;
      font-size: 15rem;
      line-height: 24rem;
      margin-bottom: 23rem;
      text-align: justify;
    }
  }
  .img {
    img {
      width: 100%;
    }
  }
  .list {
    position: absolute;
    bottom: -18rem;
    left: 0;
    width: 33.33%;
    z-index: 2;
  }
  .list {
    display: flex;
    > div {
      width: 100%;
    }
  }
  ul {
    width: max-content;
    li {
      width: 100%;
      position: relative;
      &:nth-last-child(1) {
        a {
          border: 0;
        }
      }
      a {
        color: #888888;
        font-size: 20rem;
        line-height: 64rem;
        border-bottom: 1px solid #2b2b2b;
        display: block;
        cursor: pointer;
      }
      &.active {
        a {
          display: block;
          color: #ffffff;
          width: 100%;
          &:after {
            content: "";
            display: block;
            height: 3px;
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(
              90deg,
              #d33328 6.76%,
              #fee33c 29.83%,
              #f3f6d4 46.16%,
              #5de9f6 63.9%,
              #2535a0 91.07%
            );
          }
        }
      }
    }
  }
}
.g-btnl{
    overflow: hidden;
    font-size: 16rem;
    line-height: 1.562em;
    color: #fff;
    cursor: pointer;
    img{
      display: inline-block;
      vertical-align: middle;
      width: 16rem;
      height: 12rem;
      margin-top: -2rem;
      margin-left: 13rem;
    }
  }

// 行业累计
.accumulate {
  margin-top: 62rem;
  .title {
    font-size: 38rem;
  }
  .list {
    ul {
      display: flex;
      li {
        width: 20%;
        text-align: center;
        position: relative;
        span {
          font-size: 46rem;
          font-weight: bold;
          line-height: 68rem;
          height: 68rem;
          margin-top: 12rem;
          display: block;
          // border-right: 1px solid #2b2b2b;
        }
        p {
          margin-top: 36rem;
          color: #838383;
        }
      }
      li:after{
        content: "";
        position: absolute;
        top: 6rem;
        right: 0;
        width: 0;
        bottom: 39rem;
        border-right: 1px solid #2b2b2b;
      }
    }
  }
}
// 荣誉
.honor {
  margin-top: 68rem;
  .title {
    font-size: 38rem;
  }
  .list {
    ul {
      overflow: hidden;
      li {
        float: left;
        width: 20%;
        height: 120rem;
        // padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 80%;
          max-height: 80%;
          object-fit: contain;
        }
      }
    }
  }
}
// 核心服务应用
.service-app {
  margin-top: 68rem;
  // padding-bottom: 62rem;
  h3 {
    font-size: 38rem;
    font-weight: 500;
  }
  .list {
    margin-top: 17rem;
    .item{
      position: relative;
      margin-top: 40rem;

    }
    .con{
      display: block;
      overflow: hidden;
      position: relative;
      animation: spin 2.5s infinite linear paused;
      background:
      linear-gradient(to bottom, transparent, transparent) padding-box,
      conic-gradient(from var(--bg-angle), #D33328 6.53%, #FEE33C 28.81%, #F3F6D4 44.6%, #5DE9F6 51.73%, #2535A0 70.99%,#D33328 99%) border-box;
      &:hover {
        animation-play-state: running;

      }
      &:hover{
        .img{
          transform:scale(0.98,0.97);
        }
      }
      .img{
        background: #000;
        display: block;
        width: 100%;
        height: 100%;
        transition: .5s;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      h5 {
        position: absolute;
        font-size: 26rem;
        top: 44rem;
        left: 40rem;
        font-weight: 500;
        color: #fff;
      }
      p {
        position: absolute;
        top: 107rem;
        left: 40rem;
        color: #838383;
        margin-right: 52rem;
      }
    }
  }
}
.g-videol{
  overflow: hidden;
  display: block;
  width: 100%;
  position: relative;
  .video{
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .play{
    position: absolute;
    width: 48rem;
    height: 48rem;
    right: 41rem;
    bottom: 56rem;
    overflow: hidden;
    z-index: 10;
    cursor: pointer;
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
.wp1{
    max-width: 1760px;
    width: 92%;
    margin: 0 auto;
}
@media only screen and (max-width: 1440px){
 .solution{
   .tab{
     > div {
       font-size: 16rem;
       span{
         img{
           width: 36rem;
         }
       }
     }
   }
 }
}



@media only screen and (max-width: 1200px){
  .wp1{
    width: auto;
    max-width: none;
  }
  .banner{
    .top{
      height: auto;
      padding: 21rem 0 10rem;
      .title{
        font-size: 34rem;
        line-height: 1.205em;
        margin-bottom: 28rem;
      }
      .right{
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        font-size: 16rem;
        width: 100%;
        .button{
          margin-top: 0;
          svg{
            width: 32rem;
            height: 32rem;
          }
        }
      }
    }
  }

  

  // 解决方案
  .solution {
    padding-bottom: 6rem;
    overflow: hidden;
    padding-left: 2rem;
    padding-right: 2rem;
    .solution-title {
      > div {
        font-size: 28rem;
        line-height: 1.214em;
        padding: 50rem 0 30rem;
      }
    }
    .tab{
      display: block;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      > div{
        display: inline-block;
        margin-right: 33rem;
        font-size: 16rem;
        span{
          padding-bottom: 10rem;
          >img{
            display: none;
            margin-right: 0;
          }
        }
      }
    }
    .list-title{
      padding-top: 29rem;
      p{
        font-size: 28rem;
        line-height: 1.214em;
        padding-top: 13rem;
        padding-bottom: 42rem;
        padding-right: 0;
      }
      .tips-m{
        display: block;
        color: #86868b;
      }
      .tips-pc{
        display: none;
      }
      span{
        font-size: 18rem;
      }
    }
    .list{
      border: none;
      margin-top: 0;
      margin: 0;
      padding: 0;
      > div{
        display: block;
        width: 50%;
        padding: 33rem 8rem 26rem;
        letter-spacing: normal;
        &:nth-child(odd){
          padding-left: 0;
        }
        &:nth-child(even) {
          padding-right: 0;
        }
        > img{
          width: 32rem;
          margin: 0 0 10rem;
        }
        h5{
          font-size: 20rem;
          line-height: 1.2em;
          height: 2.4em;
          display: -webkit-box;
          box-orient: vertical;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          overflow: hidden;
          max-width: 6em;
          font-weight: normal;
        }
        p{
          font-size: 14rem;
          line-height: 1.5em;
          height: 6em;
          display: -webkit-box;
          box-orient: vertical;
          -webkit-box-orient: vertical;
          line-clamp: 4;
          -webkit-line-clamp: 4;
          overflow: hidden;
          margin-top: 14rem;
        }
      }
    }
  }

  // 品牌
  .brand {
    margin-top: 42rem;
    .pic{
      display: block;
      width: 100%;
      text-align: center;
      line-height: 70rem;
      height: 70rem;
      img {
        max-width: 70%;
        max-height: 40%;
      }
    }
  }

  // 作品案例

  .case{
    margin-top: 42rem;
    .topping{
      display: none;
      .left{
        .top{
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: space-between;
        }
        // display: block;
        h2{
          font-size: 28rem;
          line-height: 1.214em;
        }
        .logo{
          width: 87rem;
          margin-top: 0;
        }
        .bl{
          overflow: hidden;
          margin-bottom: 30rem;
          h3{
            font-size: 28rem;
            line-height: 1.214em;
            width: auto;
            margin: 20rem 0;
            br{
              display: none;
            }
          }
          span:first-child{
            display: none;
          }
        }
      }
    }
  }

  // 场景
  .scene{
    margin-top: 56rem;
    +.body-line{
      display: none;
    }
    .con{
      flex-wrap: wrap-reverse;
      .left{
        .info{
          padding-top: 16rem;
        }
        span,p{
          font-size: 16rem;
          line-height: 1.5em;
        }
        h3{
          font-size: 28rem;
          line-height: 1.214em;
          margin: 21rem 0 8rem;
        }
        p{
          color: #86868b;
        }
      }
    }
    .list{
      position: static;
      width: auto;
      justify-content: space-between;
      margin-top: 80rem;
      .item{
        width: 46%;
      }
    }
    ul{
      width: 100%;
      li{
        &:nth-last-child(1) {
          a {
            border-bottom: 1px solid #2b2b2b;
          }
        }
        a{
          font-size: 17rem;
          letter-spacing: 0;
          text-overflow:ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 54rem;
        }
      }
    }
  }

  .g-btnl{
    overflow: hidden;
    font-size: 16rem;
    line-height: 1.562em;
    color: #fff;
    cursor: pointer;
    img{
      display: inline-block;
      vertical-align: middle;
      width: 16rem;
      height: 12rem;
      margin-top: -2rem;
      margin-left: 13rem;
    }
  }

  // 行业累计
  .accumulate{
    margin-top: 53rem;
    .title{
      font-size: 28rem;
      line-height: 1.214em;
      overflow: hidden;
    }
    .list{
      margin-top: 36rem;
      ul{
        flex-wrap: wrap;
        li{
          width: 33.33%;
          box-sizing: border-box;
          border-right: 1px solid #2b2b2b;
          border-top: 1px solid #2b2b2b;
          height: 145rem;
          padding-top: 7rem;
          &:nth-child(3n){
            border-right: none;
          }
          &:nth-child(1),
          &:nth-child(2){
            border-top: none;
          }
          span{
            border-right: none;
            font-size: 34rem;
            line-height: 1.823em;
            height: auto;
            font-style: italic;
          }
          p{
            color: #838383;
            font-size: 14rem;
            margin-top: 21rem;
          }
        }
      }
    }
  }

  // 荣誉
  .honor{
    margin-top: 53rem;
    .title{
      font-size: 28rem;
      line-height: 1.214em;
    }
    .list{
      margin-top: 21rem;
      ul{
        li{
          width: 33.33%;
        }
      }
    }
  }

  // 核心服务应用
  .service-app{
    margin: 55rem 0 33rem;
    padding-bottom: 0;
    h3{
      font-size: 28rem;
      line-height: 1.214em;
      width: 89%;
      margin: 0 auto;
    }
    .list{
      margin-top: 34rem;
      display: block;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      font-size: 0;
      padding-left: 5.5%;
      .item{
        overflow: hidden;
        margin: 0 0 20rem;
        margin: 0 10rem 20rem 0;
        display: inline-block;
        vertical-align: top;
      }
      .con{
        width: 150rem;
        height: 188rem;
        img{
          width: 100%;
          height: 188rem;
          object-fit: cover;
        }
        p{
          display: none;
        }
        h5{
          font-size: 21rem;
          top: 14rem;
          left: 14rem;
          right: 13rem;
          white-space: normal ;
          box-sizing: border-box;
        }
      }
    }
  }
  // 作品案例
  .case{
    .list{
      margin-top: 34rem;
      li{
        .logo{
          top: 18rem;
          left: 18rem;
        }
        .text{
          left: 18rem;
          right: 18rem;
          bottom: 18rem;
          .tips{
            display: none;
          }
          .desc{
            font-size: 14rem;
          }
          h3{
            font-size: 21rem;
            text-overflow:ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
    .more{
      display: none;
    }
  }
  

}

@media only screen and (max-width: 767px){
  .banner{
    .top{
      .title{
        max-width: 7em;
        letter-spacing: normal;
        padding-top: 0;
      }
      .right{
        .button{
          svg{
            margin-left: 12rem;
          }
        }
      }
    }
  }
  // 作品案例
  .case{
    .list{
      display: block;
      li{
        width: auto;
      }
    }
    .more{
      display: none;
    }
  }

  // 行业累计
  .accumulate{
    .list{
      ul{
        li{
          width: 50%;
          &:nth-child(3n){
            border-right: 1px solid #2b2b2b;
          }
          &:nth-child(2n){
            border-right: none;
          }
          &::after{
              display: none;
            }
        }
      }
    }
  }
}
</style>
