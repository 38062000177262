(function () {
  var zoom = window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI;

  fontSize();



  function fontSize() {
    var size;
    var winW = window.innerWidth;
    /* if (winW <= 1800 && winW > 800) {
      size = winW / 1800;
    } else if (winW <= 800) {
      size = 1
    } else {
      // size = 100;
      size = winW / 1920 
    } */
    if(winW > 1199){
      size = winW / 1920 
    }else  if (winW > 767){
      size = winW / 1000 
    }else{
      size=1
    }
  
    document.getElementsByTagName("html")[0].style.fontSize = size + "px"
    /* if (zoom == 1.25) {
      $('html').addClass('zoom125')
    } else if (zoom == 1.5) {
      $('html').addClass('zoom150')
    } */

    window.addEventListener("resize", function () {
      fontSize();
    });

  }
})();