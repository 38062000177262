import { post } from '@/utils/request'
import {apiUrl} from "./config.js"

export default class apiCommonCategory {
  static module : string  = ''
  static async getList(page = 1, pageSize = 99, map = [], alias = '', join = []) {
    let module = this.getModule()
    return post(apiUrl + `/home/${module}/getList`, { page, pageSize, map, alias, join })
  }

  // static async save(module, data) {
  //   return post(`/home/${module}/save`, data)
  // }
  //
  // static async del(module, id) {
  //   return post(`/home/${module}/listDel`, { id })
  // }

  static camelToUnderscore(str: string) {
    return str.replace(/([A-Z])/g, "_$1").toLowerCase();
  }

  static getModule(m: string|undefined=undefined) {
    if(m !== undefined) return m
    if(this.module)
      return this.module
    console.error('api接口未指定模块')
    // toast.error('api接口未指定模块')
    // let name = this.name
    // if(name.substr(0, 3) === 'api') {
    //   name = name.substr(3)
    // }
    // name = this.camelToUnderscore(name)
    // if(name.substr(0,1) === '_')
    //   name = name.substr(1)
    // return name
  }
}
