import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Home from '@/page/home.vue'
import AutoArea from '@/page/autoArea.vue'

const routes = [
  { path: '/', component: Home },
  // 汽车领域
  { path: '/autoArea', component: AutoArea },
  // worklist
  { path: '/WorkList', component: () => import('@/page/WorkList.vue') },
  // work
  { path: '/Work', component: () => import('@/page/Work.vue') },
  // contact
  { path: '/ContactUs', component: () => import('@/page/ContactUs.vue') },
  // about
  { path: '/about', component: () => import('@/page/about.vue') },
  // 云3D看车
  { path: '/Cloud3D', component: () => import('@/page/Cloud3D.vue') },
  // 品牌车型数字展厅
  { path: '/VehicleModel', component: () => import('@/page/VehicleModel.vue') },
  // 品牌智能技术展厅
  { path: '/intelligence', component: () => import('@/page/intelligence.vue') },
  // 车企全链路数字门店
  { path: '/Metaverse', component: () => import('@/page/Metaverse.vue') },
  // 应用服务-频道页
  { path: '/appService', component: () => import('@/page/appService.vue') },
  // 行业方法-频道页
  { path: '/industrySolutions', component: () => import('@/page/industrySolutions.vue') },
    // 详情页
  { path: '/detail', component: () => import('@/page/detail.vue') },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
