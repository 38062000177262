import { post } from '@/utils/request'

export const apiUrl = 'http://admin.metafuture.cn'

export default class apiConfig {
  static async getList(page = 1, limit = 999, map = [], alias = '', join = []) {
    return post(apiUrl + '/admin/config/getList', { page, limit, map, alias, join })
  }

  static async save(data) {
    return post(apiUrl +'/admin/config/save', data)
  }

  static async getQysConfig() {
    return post(apiUrl + '/admin/config/getQysConfig')
  }
}
