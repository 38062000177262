import { createApp } from 'vue'
import { createPinia } from "pinia";
import './style/global.less'
import './style/comment.less'
import './style/rem.js'
import App from './App.vue'

import router from './router'

const app = createApp(App)
app.use(router)

const pinia = createPinia()
app.use(pinia)

app.mount('#app')
